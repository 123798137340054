.styled-select {
  width: 100%;
  color: black;
  padding: 10px;
}

.navbarGrid {
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  gap: 0px 0px;
  grid-template-areas: ". logo language-button";
}

.languageSelector {
  margin-left: auto;
  grid-column-start: 4;
  grid-area: language-button;
}

.languageSelector div a {
  color: #232868 !important;
  filter: drop-shadow(1px 1px 2px #fdfdfd);
  font-size: 17px;
}

.languageButton {
  position: absolute;
  right: 20px;
  top: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spaceBetween {
  justify-content: space-between;
}

.responsiveButtonContainer {
  display: flex;
  align-items: center;
  min-width: 100%;
  margin: 0 auto!important;
  justify-content: space-between;
}

.responsiveButtonContainer button {
  margin-right: 10px;
}

.landingWidth{
  width: 380px;
  border-radius: 7px!important;
}

.mapBox{
  border-radius: 7px;
}
.mapGoogle{
  padding: 10px;
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height:100%;
  background:url('bg14.jpg') rgba(194, 194, 194, 0.815);
  background-size:cover;
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-size: auto;
  background-attachment: fixed;
  background-size:cover;

}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.col{
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.822);
  padding: 25px;
}

.noBorderRadious{
  border-radius: 0px!important;
}

.noBorderRadiousTop{
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.noBorderRadiousBottom{
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

}


.h2{
  color: #2470b8d8!important ;
}

.form-label{
  color: #2470b8d8!important ;
  font-weight: 400;
}
.text-muted {
  color: #ad3222d5!important;
  font-size: 18px;
}
.text-black {
  color: #0d2161d5!important;
  font-size: 25px;
  font-weight: 400;
  padding-bottom: 7px;
}

.linkButton {
  background: none;
  border: none;
  text-decoration: none;
  color: rgba(43, 115, 248, 0.877);
  font-weight: 600;
  text-decoration: underline;
  display: inline-block;
}


#pac-input {
  display: block!important;
  width: 100%!important;
  min-width: 300px!important;
  padding: 1px!important;
  height: calc(1.5em + .75rem + 2px)!important;
  padding: .375rem .75rem!important;
  font-size: 1rem!important;
  font-weight: 400!important;
  line-height: 1.5!important;
  color: #495057!important;
  background-color: #fff!important;
  background-clip: padding-box!important;
  border: 1px solid #ced4da!important;
  border-radius: .25rem!important;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out!important;

}

#logo{
  background-image: url('logo.png');
  background-size: 400px;
  height: 70px;
  width: 400px;
  filter: drop-shadow(1px 1px 1px #070708a6);
  margin-top: 30px!important;  
  grid-area: logo;
}

.wrap {
  background: rgba(255, 255, 255, 0);
  padding-top: 1px;
  padding-bottom: 25px;
}

.Apointment{
  padding: 20px;

}

.height{
  height: 500px!important;
  padding-left: 80px;
  background-color:rgba(255, 255, 255, 0)!important;
}

.image{
  background-image: url('inPerson.png') ;
  background-size: contain;
  width: 350px;
  height: 250px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat!important;

}

.btwn{
padding: 10px;
}

.btn{
  height: 40px;
}

.r {
  position: absolute;
  right: 0;
  padding-right: 40px;
}

.test{
  color:red;
}

.twobox{
  display: flex;
  align-items: center;
}

.btnCenter{
 margin:0 auto!important;
 position: absolute;
 bottom: 40px;
}

.imagePerson{
  height: 500px;
}

.mButton{
  margin-top: 15px;
}

.rowWide {
  margin: 0 auto!important;
}

.maxWidth-90 {
  max-width: 90%;
}

.select-center {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
}

.block {
  display: block;
  margin: 0 auto;
}

.buttonsColumn button {
  display: block;
  margin: 0 auto 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

@media only screen and (min-width: 800px) {
  .rowWide {
    max-width: 50vw;
  }
  .mt-5-desktop {
    margin-top:3rem!important;
  }
  .text-3-desktop {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 800px) {
  .languageSelector {
    margin: 0px!important;
  }

  .navbarGrid #logo {
    margin-bottom: 0px!important;
  }
  .navbarGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 0px;
    grid-template-areas: "logo" "language-button";
  }

  #logo{
    background-image: url('logo.png');
    background-size: 300px;
    height: 55px;
    width: 300px;
  
    margin-top: 25px!important;
    padding-top: 5px;
    
  }

  .landingWidth{
    width: 250px;

  }

  body {

    min-height:100%;
    background:url('bg15.jpg') rgba(194, 194, 194, 0.815);
    background-size: cover!important;
    background-attachment: fixed;
  
  }

  .responsiveButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  
.image{
  background-image: url('inPerson.png') ;
  background-size: contain;
  width: 715px;
  /* height: 400px; */

}

}
/*  

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 1) {

    body {

      min-height:100%;
      background:url('bg16.jpg') rgba(194, 194, 194, 0.815);
      background-size: cover;
      background-attachment: fixed;
    
    }

    .landingWidth{
      width: 45%;
    }

    .image{
      background-size: contain;
      width: 715px;
      height: 390px;
    
    }

}



/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) { 


    .landingWidth{
      width: 65%;
    }
  

}

/* Portrait */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 

    .landingWidth{
      width: 75%;
    }

    .image{
      background-size: contain;
      width: 715px;
      height: 230px;
    
    }
  
}

/* Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) { 

    .landingWidth{
      width: 95%;
    }

    .image{
      background-size: contain;
      width: 715px;
      height: 330px;
    
    }
}



/* ----------- iPhone 6+, 7+ and 8+ ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3) { 

    .landingWidth{
      width: 80%!important;
    }

    .image{
      background-size: contain;
      width: 625px;
      height: 280px!important;
    
    }
}

/* Portrait */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .landingWidth{
      width: 50%;
    }

    .image{
      background-size: contain;
      background-repeat: no-repeat!important;
      width: 625px;
      height: 330px;
    
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) { 

}


/* MEDIUM */

@media only screen and (max-width: 767px) and (min-width: 601px)  {

  .landingWidth{
    width: 50%;
}

}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {

        .landingWidth{
      width: 95%;
    }

    .image{
      background-size: contain;
      background-repeat: no-repeat!important;
      width: 625px;
      height: 230px;
    
    }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {

}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .landingWidth{
      width: 70%;
    }

    .image{
      background-size: contain;
      background-repeat: no-repeat!important;
      width: 625px;
      height: 230px;
    
    }

}


/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {

}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {

    
    .landingWidth{
      width: 50%;
    }

    .image{
      background-size: contain;
      background-repeat: no-repeat!important;
      width: 625px;
      height: 230px;
    
    }

}

@media screen and (min-device-width: 1025px) and (max-device-width: 1514px)  { 
  .landingWidth{
    width: 30%;
  }
}

@media screen and (min-device-width: 630px) and (max-device-width: 767px) { 
  .landingWidth{
    width: 60%;
  }
}


@media screen and (min-device-width: 768px) and (max-device-width: 1024px) { 
  .landingWidth{
    width: 40%;
  }
}

@media screen and (min-device-width: 1322px) and (max-device-width: 1514px) { 
  .landingWidth{
    width: 25%!important;
  }
}




@media screen and (min-device-width: 2047px) { 
  .landingWidth{
    width: 14%!important;
  }
}


@media screen and (min-device-width: 3047px) { 
  .landingWidth{
    width: 11%!important;
  }
}


@media screen and (min-device-width: 1024px) { 
  .responsiveButtonContainer {
    width: 80%;
  }
}

@media screen and (max-device-width: 315px) {
  .landingWidth{
    width: 99%;
  }
} 

